<template>
  <div
    class="custom-shipping-containers px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <!--       <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/TestEquipment/dotcontainer1-min.jpg"
          width="305"
          height="228"
          alt="DOT Shipping Containers"
          title="DOT Shipping Containers"
          class="img-responsive m-auto"
        />
      </div> -->
      <div class="w-full mb-6">
        <TitleComponent title="Custom Shipping Containers" size="large" />
        <Paragraph
          ><p>
            SMS has the capabilities to manufacture custom shipping containers
            that are utilized to transport Class 1 materials. Some class 1
            articles are unique in size and weight and require custom design and
            manufacturing to meet the robustness required to safely transport
            these materials. Any package containing a hazardous material must be
            Performance Oriented Packaged (POP) Tested in accordance with
            49CRF178. SMS customers have found that obtaining custom containers
            is not the problem but finding someone that can POP certify these
            large and custom containers can be challenging. SMS has the
            capability to
            <u>both manufacture and POP certify these custom packages.</u> As an
            approved explosives laboratory by the US DOT, SMS is familiar with
            navigating the regulations to ensure that not only are the packages
            meeting the requirements as outlined in the code of federal
            regulations (CFR) but that the subsequent shipments of hazardous
            materials are conducted appropriately. SMS’ experience in
            manufacturing and POP testing/certifying these custom containers
            coupled with our explosive testing and understanding of the CFR
            regulations uniquely qualify SMS for these types of projects.
          </p>
          <p>
            Below are a few examples of some of the custom shipping containers
            that SMS has designed, manufactured, and POP Certified. Items can be
            purchased by calling 801-567-0456.
          </p>
        </Paragraph>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 mb-6 md:pr-2">
          <img
            src="@/assets/TestEquipment/custom1-min.png"
            alt="Custom Shipping Container 1"
            title="Custom Shipping Container 1"
            class="img-responsive m-auto"
          />
        </div>
        <div class="w-full md:w-1/2 mb-6 md:pr-2">
          <img
            src="@/assets/TestEquipment/custom2-min.png"
            alt="Custom Shipping Container 2"
            title="Custom Shipping Container 2"
            class="img-responsive m-auto"
          />
        </div>
        <div class="w-full md:w-1/2 mb-6 md:pr-2">
          <img
            src="@/assets/TestEquipment/custom3-min.png"
            alt="Custom Shipping Container 3"
            title="Custom Shipping Container 3"
            class="img-responsive m-auto"
          />
        </div>
        <div class="w-full md:w-1/2 mb-6 md:pr-2">
          <img
            src="@/assets/TestEquipment/custom4-min.png"
            alt="Custom Shipping Container 4"
            title="Custom Shipping Container 4"
            class="img-responsive m-auto"
          />
        </div>
        <div class="w-full md:w-1/2 mb-6 md:pr-2">
          <img
            src="@/assets/TestEquipment/custom5-min.png"
            alt="Custom Shipping Container 5"
            title="Custom Shipping Container 5"
            class="img-responsive m-auto"
          />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full md:w-1/2 mb-6 md:pr-2">
          <img
            src="@/assets/TestEquipment/custom6-min.png"
            alt="Custom Shipping Container 6"
            title="Custom Shipping Container 6"
            class="img-responsive m-auto"
          />
        </div>
        <div class="w-full md:w-1/2 mb-6 md:pr-2">
          <img
            src="@/assets/TestEquipment/custom7-min.png"
            alt="Custom Shipping Container 7"
            title="Custom Shipping Container 7"
            class="img-responsive m-auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";

export default {
  metaInfo: {
    title: "Custom Shipping Containers / POP Testing",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS manufactures shipping containers for transportation under DOT Special Permit 8451 (DOT-SP 8451) and DOT Special Permit 13481 (DOT-SP 13481)."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph
  }
};
</script>

<style lang="scss">
.custom-shipping-containers {
  a {
    color: #aa222c;
  }
  h2 {
    color: black;
    font-size: 18px;
    margin-bottom: 1.1em;
  }
}
</style>
